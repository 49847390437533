import * as React from "react";
import { Helmet } from "react-helmet";
import favicon from "./favicons/favicon.ico";
import appleTouchIcon from "./favicons/apple-touch-icon.png";

const Seo = ({ pageTitle, description }) => {
  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
      title={pageTitle}
      defaultTitle={pageTitle}
      link={[
        {
          rel: "icon",
          href: favicon,
        },
        {
          rel: "apple-touch-icon",
          type: "image/png",
          href: appleTouchIcon,
        },
      ]}
      meta={[
        {
          name: `description`,
          content: description,
        },
      ]}
    >
      <script src={`${process.env.GATSBY_OSANO_SCRIPT}`} />
      {/*Google Tag Manager*/}
      <script>
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${process.env.GATSBY_GTM_ID}')
        `}
      </script>
      {/* AudioEye */}
      <script type="text/javascript">{`!function(){var b=function(){window.__AudioEyeSiteHash = "22e5dc695afcd027c33165703e1d8303"; var a=document.createElement("script");a.src="https://wsmcdn.audioeye.com/aem.js";a.type="text/javascript";a.setAttribute("async","");document.getElementsByTagName("body")[0].appendChild(a)};"complete"!==document.readyState?window.addEventListener?window.addEventListener("load",b):window.attachEvent&&window.attachEvent("onload",b):b()}();`}</script>
    </Helmet>
  );
};

export default Seo;
